export const CURRENT_WEIGHT_REQUIRED = 'CurrentWeightNeeded';
export const DAY_PLAN = 'UserDailyDietPlanSuccess';
export const DAY_REDIRECT = 'DoesNotExistDisplayAnotherDay';
export const DIET_IN_FUTURE = 'DietWillBeAvailableInFuture';
export const DIET_INTRO = 'DietIntroduction';
export const DIET_SETTINGS = 'DietSettings';
export const GOAL_LOST = 'GoalLost';
export const GOAL_REACHED = 'GoalReached';
export const OUTSIDE_ACCESS = 'DietOutsideAccess';
export const PAYMENT_PENDING = 'PendingPayment';
export const UNAUTHORIZED = 'UnauthorizedException';
export const DIET_COMPLETED = 'DietCompleted';
export const AFTER_LONG_BREAK = 'AfterLongBreak';
