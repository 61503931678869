import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label, Row, Col } from 'reactstrap';
import InputRadio from 'components/FormElements/InputRadioControlled';
import InputDate from 'components/FormElements/InputDateControlled';
import { withLocale } from '@dietlabs/components';
import withFirebase from 'view/FirebaseAnalytics/withFirebase';
import SlideRuler from 'slide-ruler';
import withStep from './StepContext/withStep';

class StepSexBirthdayHeight extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        sex: PropTypes.string,
        systemOfMeasures: PropTypes.oneOf(['imperial', 'si']),
        height: PropTypes.shape({
            value: PropTypes.number,
            unit: PropTypes.string.isRequired,
        }),
        dateOfBirth: PropTypes.string,
        handleInputChange: PropTypes.func.isRequired,
        changeNextBtnState: PropTypes.func.isRequired,
        errors: PropTypes.shape(),
        validateData: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
    };

    static defaultProps = {
        sex: undefined,
        systemOfMeasures: 'si',
        height: { value: undefined, unit: 'cm' },
        dateOfBirth: undefined,
        errors: undefined,
    };

    slideRulerRef = React.createRef();

    componentDidMount() {
        const size = this.updateSize();
        this.renderSlideRuler(size);
        this.checkErrors();
        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'data',
        });
    }

    componentDidUpdate() {
        this.checkErrors();
    }

    checkErrors = () => {
        let errors = false;

        if (this.props.errors) {
            this.props.errors.details.forEach(error => {
                if (
                    (error.fieldName === 'sex' && !this.props.sex) ||
                    error.fieldName === 'dateOfBirth' ||
                    error.fieldName === 'height.value'
                ) {
                    errors = true;
                }
            });
        }

        this.props.changeNextBtnState(
            !(
                this.props.sex &&
                this.props.height.value &&
                this.props.dateOfBirth &&
                !errors
            )
        );
    };

    updateSize = () => {
        return [window.innerWidth, window.innerHeight];
    };

    getWindowSize = () => {
        const size = this.updateSize();
        return size;
    };

    renderSlideRuler = size => {
        const options = {
            el: this.slideRulerRef.current,
            currentValue: this.props.height.value,
            handleValue: this.handleValue,
            precision: 1,
        };

        const { systemOfMeasures } = this.props;
        if (systemOfMeasures === 'imperial') {
            options.minValue = 40;
            options.maxValue = 90;
        } else if (systemOfMeasures === 'si') {
            options.minValue = 101;
            options.maxValue = 230;
        } else {
            throw new Error(
                `Unsupported system of measures: ${systemOfMeasures}`
            );
        }

        if (size[0] >= 576 && size[0] < 768) {
            options.canvasWidth = 570;
        } else if (size[0] >= 768 && size[0] < 992) {
            options.canvasWidth = 500;
        } else if (size[0] >= 992) {
            options.canvasWidth = 495;
        }

        return new SlideRuler(options);
    };

    handleValue = value => {
        if (value !== this.props.height.value) {
            const event = {
                target: {
                    name: 'height.value',
                    value,
                },
            };

            this.props.handleInputChange(event);
        }
    };

    render() {
        const {
            sex,
            height,
            errors,
            dateOfBirth,
            handleInputChange,
        } = this.props;

        const optionalHeightProps = {};
        if (height.value) {
            optionalHeightProps.value = Number(height.value);
        }

        return (
            <div className="full-screen3">
                <div className="mb-auto w-100">
                    <FormGroup tag="fieldset">
                        <Label>{this.props.t('diet-settings/sex/label')}</Label>
                        <Row>
                            <Col xs="6">
                                <InputRadio
                                    label={
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/sex/female'
                                            )}
                                        </strong>
                                    }
                                    name="sex"
                                    value="female"
                                    handleChange={handleInputChange}
                                    checked={sex === 'female'}
                                    data-test="sex-female"
                                />
                            </Col>
                            <Col xs="6">
                                <InputRadio
                                    label={
                                        <strong>
                                            {this.props.t(
                                                'diet-settings/sex/male'
                                            )}
                                        </strong>
                                    }
                                    name="sex"
                                    value="male"
                                    handleChange={handleInputChange}
                                    checked={sex === 'male'}
                                    data-test="sex-male"
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <InputDate
                        label={this.props.t('diet-settings/birth-date')}
                        name="dateOfBirth"
                        value={dateOfBirth}
                        errors={errors}
                        handleChange={handleInputChange}
                        onBlur={() => this.props.validateData()}
                        data-test="dateOfBirth-input"
                    />

                    <Label for="ruler-slider">
                        {this.props.t('diet-settings/height')}
                    </Label>

                    <div className="ruler-slider" id="ruler-slider">
                        <h3 className="text-center">
                            <span className="big-number">{height.value}</span>{' '}
                            {height.unit}
                        </h3>
                        <div ref={this.slideRulerRef} />
                    </div>
                </div>
            </div>
        );
    }
}

export default withFirebase(withStep(withLocale(StepSexBirthdayHeight)));
