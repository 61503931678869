import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col, Button } from 'reactstrap';

import Layout from 'Layout/Layout';
import { PATHS } from 'config/paths';
import signet from 'assets/images/sygnet_dr_dabrowska.jpg';
import { withLocale } from '@dietlabs/components';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { callNativeApp } from 'components/Helpers/callNativeApp';
import { formatMediumDate } from 'utils/dateFormatter';

class OutsideAccess extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        renderTimeline: PropTypes.func.isRequired,
        accessTo: PropTypes.oneOfType([
            PropTypes.oneOf([undefined]),
            PropTypes.instanceOf(Date).isRequired,
        ]).isRequired,
        hasActiveSubscription: PropTypes.bool.isRequired,
    };

    renderDate() {
        if (this.props.accessTo) {
            return formatMediumDate(this.props.accessTo);
        }

        return null;
    }

    renderDays() {
        if (this.props.accessTo) {
            const today = new Date();
            const dietAccessEndDate = this.props.accessTo;
            const dietAccesEndDays = Math.ceil(
                (dietAccessEndDate.getTime() - today.getTime()) /
                    (1000 * 60 * 60 * 24)
            );
            return dietAccesEndDays;
        }

        return this.props.t('info-pages/diet-outside-access/unknown-number');
    }

    renderHeading() {
        if (this.props.hasActiveSubscription) {
            return this.props.t('info-pages/diet-outside-access/title', {
                count: this.renderDays(),
                date: this.renderDate(),
            });
        }
        return this.props.t('info-pages/diet-outside-access/web-title', {
            count: this.renderDays(),
            date: this.renderDate(),
        });
    }

    renderAction() {
        if (this.props.mobileAppMode) {
            return (
                <Button
                    color="primary"
                    className="mb-4"
                    onClick={() => callNativeApp('/', 'buy-diet')}
                >
                    {this.props.t('button/buy-subscribtion')}
                </Button>
            );
        }

        return (
            <a
                className="mb-4 btn btn-primary"
                href={`${PATHS.EXTERNAL.HOME}#buy`}
            >
                {this.props.t('buy')}
            </a>
        );
    }

    render() {
        return (
            <Layout page="diet">
                {this.props.renderTimeline('top')}
                <section>
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <img
                                    className="mb-4"
                                    src={signet}
                                    alt=""
                                    role="presentation"
                                />
                                <h1 data-test="header">
                                    {this.renderHeading()}
                                </h1>
                                <p>
                                    {this.props.t(
                                        'error/unauthorized/subscription/expired/content'
                                    )}
                                </p>
                                <p>{this.renderAction()}</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Layout>
        );
    }
}

export { OutsideAccess };
export default withMobileAppMode(withLocale(OutsideAccess));
