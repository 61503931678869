import React, { Component } from 'react';
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormGroup,
    InputGroup,
    Input,
} from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import Loader from 'Layout/Loader';
import withMobileAppMode from 'Layout/withMobileAppMode';
import Menu from 'Layout/Menu';

import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';

class DayPlanChangeDay extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        loadDayPlanDayReplacements: PropTypes.shape().isRequired,
        renderOriginalDay: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        // currentDayPlan: PropTypes.arrayOf(PropTypes.shape()).isRequired,
        toggleModalWithDayReplacements: PropTypes.func.isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
    };

    state = {
        replacements: [],
        original: null,
        dietSetId: 0,
        search: '',
        preloadInside: false,
        preloadOutside: false,
    };

    componentDidMount() {
        document.body.style.overflow = 'hidden';
        this.setState({ preloadOutside: true });
        this.loadDayReplacements(this.state.search);

        // disable browser back button
        window.history.pushState(
            null,
            null,
            `${window.location.pathname}?wymien-jadlospis`
        );

        window.onpopstate = () => {
            this.props.toggleModalWithDayReplacements();
        };

        // routeChanged post msg
        callNativeAppRouteChanged(window.location.href, 'diet', 'Wymiana dnia');
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.onpopstate = () => {
            this.props.toggleModalWithReplacements();
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.date.getTime() !== this.props.date.getTime()) {
            // close modal
        }
    }

    handleSubmit = event => {
        event.preventDefault();
        this.setState({ preloadInside: true });
        this.loadDayReplacements(this.state.search);
    };

    handleChange = event => {
        this.setState({ search: event.target.value });
    };

    loadDayReplacements = async search => {
        const result = await this.props.loadDayPlanDayReplacements.load(search);

        this.setState({
            replacements: result.data.me.diet.set.replacements,
            original: result.data.me.diet.set.original,
            dietSetId: result.data.me.diet.set.dietSetId,
            preloadInside: false,
            preloadOutside: false,
        });
    };

    formatDate = (date, isShort) => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        let dayName = t(`meal/weekday${weekday}`);

        if (isShort) {
            dayName = t(`meal/weekday${weekday}-short`);
        }
        return `${dayName} ${day0}.${month0}`;
    };

    render() {
        // const mealsOnly = this.props.currentDayPlan.filter(
        //     event => event.__typename === 'Meal'
        // );

        return (
            <React.Fragment>
                {!this.props.mobileAppMode && (
                    <React.Fragment>
                        <Menu forceReloadLinks={false} />
                        <div className="top-bar border-bottom">
                            <Container className="py-3 px-3 px-md-0 d-flex align-items-center">
                                <Button
                                    color="secondary"
                                    size="sm"
                                    onClick={() => window.history.back()}
                                    className="mr-2 outline with-arrow-prev"
                                    style={{ minWidth: '0px' }}
                                    outline
                                >
                                    <span className="d-none d-lg-inline">
                                        {this.props.t('back')}
                                    </span>
                                </Button>
                                <h1 className="text-center w-100">
                                    <strong>
                                        {this.props.t(
                                            'meal/exchange-day-button'
                                        )}
                                    </strong>
                                    <span className="d-none d-lg-inline">
                                        &nbsp;(
                                        {this.formatDate(
                                            this.props.date,
                                            false
                                        )}
                                        )
                                    </span>
                                </h1>
                                <div className="clearfix" />
                            </Container>
                        </div>
                    </React.Fragment>
                )}
                <div className="bg-white border-bottom py-4">
                    <Container>
                        <Row>
                            <Col md={{ size: 8, offset: 2 }}>
                                <Form
                                    onSubmit={this.handleSubmit}
                                    data-test="search-form"
                                >
                                    <FormGroup
                                        row
                                        className="align-items-center mb-0"
                                    >
                                        <Col xs="12">
                                            <p className="text-center text-lg-left secondary">
                                                {this.props.t(
                                                    'meal/show-menus-contain'
                                                )}
                                            </p>
                                        </Col>
                                        <Col lg="8">
                                            <InputGroup className="form-control">
                                                <Input
                                                    type="search"
                                                    placeholder={this.props.t(
                                                        'meal/name-of-dish-or-ingredient'
                                                    )}
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                    data-test="search-input"
                                                />
                                            </InputGroup>
                                        </Col>
                                        <Col lg="4" className="text-center">
                                            <Button
                                                color="secondary"
                                                size="sm"
                                                className="mt-3 mt-lg-0 w-100"
                                            >
                                                {this.props.t('meal/search')}
                                            </Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <Container className="change-day pt-5">
                    <p className="text-center secondary">
                        {this.props.t('meal/exchange-for')}:
                    </p>

                    {this.state.replacements.length > 0 ? (
                        <Row>
                            {this.state.original.dietSetId !==
                            this.state.dietSetId ? (
                                <React.Fragment>
                                    {this.props.renderOriginalDay(
                                        this.state.original,
                                        this.state.search
                                    )}
                                </React.Fragment>
                            ) : (
                                ''
                            )}
                            <React.Fragment>
                                {this.props.children(
                                    this.state.replacements,
                                    this.state.search
                                )}
                            </React.Fragment>
                        </Row>
                    ) : (
                        <p className="text-center">
                            {this.props.t('meal/there-are-no-replacements')}
                        </p>
                    )}
                    {this.state.preloadInside ? <Loader fixed="no" /> : ''}
                    {this.state.preloadOutside ? <Loader /> : ''}
                </Container>
            </React.Fragment>
        );
    }
}

export { DayPlanChangeDay };
export default withMobileAppMode(withLocale(DayPlanChangeDay));
