/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { withLocale } from '@dietlabs/components';
import PropTypes from 'prop-types';
import withMobileAppMode from 'Layout/withMobileAppMode';
import { withSnackbar } from 'react-simple-snackbar';

import { ReactComponent as IconDone } from 'assets/images/dayplan/done-icon.svg';

import start from 'assets/images/dayplan/start.jpg';
import breakfast from 'assets/images/dayplan/breakfast.jpg';
import dinner from 'assets/images/dayplan/dinner.jpg';
import snack from 'assets/images/dayplan/snack.jpg';
import supper from 'assets/images/dayplan/supper.jpg';

import playBtn from 'assets/images/dayplan/play-button.svg';

import { callNativeAppRouteChanged } from 'components/Helpers/callNativeAppRouteChanged';
import { callNativeAppRemoveLastRoute } from 'components/Helpers/callNativeAppRemoveLastRoute';

import favouriteFull from 'assets/images/favourites/favourite-full.svg';
import favouriteEmpty from 'assets/images/favourites/favourite-empty.svg';

import withFirebase from 'view/FirebaseAnalytics/withFirebase';

import { isToday, isTomorrow, isYesterday } from 'utils/date';

const steps = [5, 15, 30, 45, 60];

class DayPlanMealDetails extends Component {
    static propTypes = {
        preparationTime: PropTypes.shape({
            years: PropTypes.number,
            days: PropTypes.number,
            hours: PropTypes.number,
            minutes: PropTypes.number,
            seconds: PropTypes.number,
        }).isRequired,
        kcal: PropTypes.number.isRequired,
        macro: PropTypes.shape({
            animalProtein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            carbohydrates: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            fat: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
            protein: PropTypes.shape({
                grams: PropTypes.number.isRequired,
                kcal: PropTypes.number.isRequired,
                percentage: PropTypes.number.isRequired,
            }).isRequired,
        }).isRequired,
        t: PropTypes.func.isRequired,
        children: PropTypes.func.isRequired,
        loadDayPlanMealReplacements: PropTypes.shape().isRequired,
        toggleModalWithReplacements: PropTypes.func.isRequired,
        toggleModal: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        mealId: PropTypes.number.isRequired,
        setMealEaten: PropTypes.shape().isRequired,
        setId: PropTypes.number.isRequired,
        mobileAppMode: PropTypes.bool.isRequired,
        preparationVideoUrl: PropTypes.string,
        preparationImageUrl: PropTypes.string,
        publicName: PropTypes.string.isRequired,
        addMealToFavorites: PropTypes.shape().isRequired,
        removeMealFromFavorites: PropTypes.shape().isRequired,
        canBeAddedToFavorites: PropTypes.bool.isRequired,
        isFavorite: PropTypes.bool.isRequired,
        openSnackbar: PropTypes.func.isRequired,
        trackFirebaseEvent: PropTypes.func.isRequired,
        date: PropTypes.instanceOf(Date).isRequired,
        isEaten: PropTypes.bool.isRequired,
        originalMealId: PropTypes.number,
        mealType: PropTypes.number.isRequired,
        mealKey: PropTypes.string.isRequired,
        showMacros: PropTypes.bool.isRequired,
    };

    static defaultProps = {
        preparationVideoUrl: undefined,
        preparationImageUrl: undefined,
        originalMealId: undefined,
    };

    videoRef = React.createRef();

    state = {
        preloadInside: false,
        visiblePoster: true,
        bodyScroll: document.body.getBoundingClientRect().top,
    };

    static calculatePreparationTime(minutes) {
        if (minutes === 0 || minutes == null) {
            return null;
        }

        // eslint-disable-next-line no-restricted-syntax
        for (const step of steps) {
            if (minutes <= step) {
                return `< ${step}`;
            }
        }
        return `> ${steps[steps.length - 1]}`;
    }

    componentDidMount() {
        document.body.style.overflow = 'hidden';

        // hack to hide large title on iOS
        setTimeout(() => {
            window.scrollTo(0, 100);
        }, 500);

        let title;

        if (isToday(this.props.date)) {
            title = 'Dzisiaj';
        } else if (isTomorrow(this.props.date)) {
            title = 'Jutro';
        } else if (isYesterday(this.props.date)) {
            title = 'Wczoraj';
        } else {
            title = this.formatDate(this.props.date);
        }

        if (window.location.href.includes('szczegoly-posilku')) {
            callNativeAppRemoveLastRoute('diet');
            // routeChanged post msg
            callNativeAppRouteChanged(window.location.href, 'diet', title);
        } else {
            // disable browser back button
            window.history.pushState(
                null,
                null,
                `${window.location.pathname}?szczegoly-posilku`
            );

            // routeChanged post msg
            callNativeAppRouteChanged(window.location.href, 'diet', title);
        }

        window.onpopstate = () => {
            this.props.toggleModal();
        };

        this.props.trackFirebaseEvent('screen_view ', {
            firebase_screen: 'Meal_details',
        });
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
        window.scrollTo(0, -this.state.bodyScroll);
        window.onpopstate = () => {};
    }

    formatDate = date => {
        const { t } = this.props;

        const weekday = date.getDay();
        const day = date.getDate();
        const day0 = day < 10 ? `0${day}` : day;
        const month = date.getMonth() + 1;
        const month0 = month < 10 ? `0${month}` : month;
        const dayName = t(`meal/weekday${weekday}-short`);

        return `${dayName} ${month0}/${day0}`;
    };

    handleMealReplace() {
        this.loadMealReplacements();
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-change_meal',
            location: 'Meal_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });
    }

    async handleSetMealEaten() {
        const isMealEaten = this.props.isEaten;

        try {
            await this.props.setMealEaten.set({
                setId: this.props.setId,
                mealId: this.props.mealId,
                eaten: !isMealEaten,
                mealKey: this.props.mealKey,
            });
        } catch (error) {
            throw new Error(`Failed to set meal eaten: ${error}`);
        }
    }

    async handleAddRemoveMealToFavorites() {
        if (!this.props.isFavorite) {
            try {
                await this.props.addMealToFavorites.addMeal(
                    this.props.mealId,
                    this.props.mealKey
                );
                if (
                    this.props.addMealToFavorites.response.me.addMealToFavorites
                        .__typename === 'BasicMutationSuccessWithId'
                ) {
                    // await this.props.refetch();

                    this.props.openSnackbar(
                        <p>{this.props.t('fav-meals/added')}</p>
                    );

                    this.props.trackFirebaseEvent('user_action', {
                        action: 'tap-add_favourites',
                        location: 'Meal_details',
                        meal_id: this.props.mealId,
                        meal_category: this.props.name,
                    });
                }
            } catch (error) {
                throw new Error(`Failed to add meal to favorites: ${error}`);
            }
        } else {
            try {
                await this.props.removeMealFromFavorites.removeMeal(
                    this.props.mealId,
                    this.props.mealKey
                );
                if (
                    this.props.removeMealFromFavorites.response.me
                        .removeMealFromFavoritesByMealId.__typename ===
                    'BasicMutationSuccess'
                ) {
                    // await this.props.refetch();

                    this.props.openSnackbar(
                        <p>
                            {this.props.t('fav-meals/removed')}
                            <Button
                                color="link"
                                onClick={() =>
                                    this.handleAddRemoveMealToFavorites()
                                }
                            >
                                {this.props.t('fav-meals/back')}
                            </Button>
                        </p>
                    );
                }
            } catch (error) {
                throw new Error(
                    `Failed to remove meal from favorites: ${error}`
                );
            }
        }
    }

    loadMealReplacements = async () => {
        this.setState({ preloadInside: true });
        const request = {
            userDietSetId: this.props.setId,
            mealType: this.props.mealType,
            dietMealId: this.props.mealId,
            limit: 10,
            prependWithOriginalMeal: !!this.props.originalMealId,
        };
        const result = await this.props.loadDayPlanMealReplacements.load(
            request
        );
        const replacements = result.data.me.dailyDietMeal.replacements;
        this.props.toggleModalWithReplacements(replacements);
        this.setState({ preloadInside: false });
    };

    playVideo = async () => {
        this.setState({ visiblePoster: false });
        this.props.trackFirebaseEvent('user_action', {
            action: 'tap-watch_video',
            location: 'Meal_details',
            meal_id: this.props.mealId,
            meal_category: this.props.name,
        });

        try {
            await this.videoRef.current.play();
        } catch (err) {
            // eslint-disable-next-line no-console
            console.warn(err);
        }
    };

    renderMealVideo = () => {
        if (!this.props.preparationVideoUrl) {
            return this.renderMealImage();
        }

        return (
            <div className="embed-responsive embed-responsive-1by1">
                <video
                    ref={this.videoRef}
                    src={this.props.preparationVideoUrl}
                    controls
                    className="embed-responsive-item"
                    playsInline
                    controlsList="nodownload"
                    disablePictureInPicture
                />
                {this.state.visiblePoster ? (
                    <div
                        style={{
                            backgroundImage: `url(${this.props.preparationImageUrl
                                .replace('(', '%28')
                                .replace(')', '%29')})`,
                        }}
                        className="embed-responsive-item d-flex align-items-center justify-content-center"
                        onClick={this.playVideo}
                    >
                        <img src={playBtn} alt="" />
                    </div>
                ) : (
                    ''
                )}
            </div>
        );
    };

    renderMealImage = () => {
        let mealImg;

        if (this.props.preparationImageUrl) {
            mealImg = this.props.preparationImageUrl;
            return (
                <div className="embed-responsive embed-responsive-1by1">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
            );
        }

        switch (this.props.name) {
            case 'Start':
                mealImg = start;
                break;
            case 'Śniadanie':
                mealImg = breakfast;
                break;
            case 'Obiad':
                mealImg = dinner;
                break;
            case 'Przekąska':
                mealImg = snack;
                break;
            case 'Kolacja':
                mealImg = supper;
                break;
            default:
                mealImg = breakfast;
        }

        return (
            <React.Fragment>
                <div className="embed-responsive d-md-none">
                    <img src={mealImg} className="img-fluid" alt="" />
                </div>

                <div className="embed-responsive embed-responsive-1by1 d-none d-md-block">
                    <div
                        style={{
                            backgroundImage: `url(${mealImg})`,
                        }}
                        className="embed-responsive-item"
                    />
                </div>
            </React.Fragment>
        );
    };

    render() {
        const isMealEaten = this.props.isEaten;
        const preparationTime = this.constructor.calculatePreparationTime(
            this.props.preparationTime.minutes
        );

        return (
            <section className="meal-details">
                <Container>
                    <Row className="align-items-center">
                        <Col md="5">
                            {this.renderMealVideo()}
                            {this.props.canBeAddedToFavorites && (
                                <button
                                    className="add-remove-fav mt-2"
                                    type="button"
                                    onClick={() =>
                                        this.handleAddRemoveMealToFavorites()
                                    }
                                >
                                    <img
                                        src={
                                            this.props.isFavorite
                                                ? favouriteFull
                                                : favouriteEmpty
                                        }
                                        alt=""
                                    />
                                </button>
                            )}
                        </Col>
                        <Col md={{ size: 6, offset: 1 }}>
                            <div className="meal-info">
                                {!this.props.mobileAppMode && (
                                    <h1 className="d-none d-md-block">
                                        {this.props.publicName}
                                    </h1>
                                )}

                                <div className="macro-and-kcal">
                                    <Row>
                                        <Col xs={{ size: 8, offset: 2 }}>
                                            <h3 className="mb-0 text-center">
                                                {this.props.t('meal/time')}{' '}
                                                <strong>
                                                    {preparationTime}{' '}
                                                    {this.props.t('meal/min')}
                                                </strong>
                                            </h3>
                                        </Col>
                                    </Row>
                                    {this.props.showMacros && (
                                        <>
                                            <hr />
                                            <div className="d-flex">
                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {this.props.kcal}
                                                        </strong>
                                                    </h3>
                                                    kcal
                                                </div>
                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .protein
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/proteins'
                                                    )}
                                                </div>
                                                <div className="flex-grow-1 text-center border-right">
                                                    <h3 className="mb-0">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .fat.grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t('meal/fat')}
                                                </div>
                                                <div className="flex-grow-1 text-center">
                                                    <h3 className="mb-0 primary semi-bold">
                                                        <strong>
                                                            {
                                                                this.props.macro
                                                                    .carbohydrates
                                                                    .grams
                                                            }
                                                            &nbsp;g
                                                        </strong>
                                                    </h3>
                                                    {this.props.t(
                                                        'meal/carbohydrates'
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>

                                <div className="action-buttons mt-4 d-none d-md-block">
                                    <Row>
                                        {!isMealEaten &&
                                            this.props.name !== 'Start' && (
                                                <Col className="pr-2 text-right">
                                                    <Button
                                                        color="secondary"
                                                        size="sm"
                                                        onClick={() =>
                                                            this.handleMealReplace()
                                                        }
                                                        data-test="change-meal-button"
                                                        className="px-0"
                                                        style={{
                                                            minWidth: '160px',
                                                        }}
                                                    >
                                                        {this.props.t(
                                                            'meal/exchange-meal-button'
                                                        )}
                                                    </Button>
                                                </Col>
                                            )}

                                        <Col
                                            className={
                                                isMealEaten ||
                                                this.props.name === 'Start'
                                                    ? 'pl-2 text-center'
                                                    : 'pl-2 text-left'
                                            }
                                        >
                                            <Button
                                                color="secondary"
                                                size="sm"
                                                outline={isMealEaten}
                                                data-test="change-meal-button"
                                                onClick={() =>
                                                    this.handleSetMealEaten()
                                                }
                                                className="px-0"
                                                style={{
                                                    minWidth: '160px',
                                                }}
                                            >
                                                {isMealEaten && <IconDone />}

                                                {isMealEaten && (
                                                    <>&nbsp;&nbsp;&nbsp;</>
                                                )}
                                                {this.props.t(
                                                    'meal/eaten-button'
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    {this.props.children()}
                </Container>

                <footer className="d-md-none">
                    <Container>
                        <Row>
                            <Col md={{ size: 6, offset: 3 }}>
                                <Row>
                                    {!isMealEaten &&
                                        this.props.name !== 'Start' && (
                                            <Col className="pr-2">
                                                <Button
                                                    color="secondary"
                                                    size="sm"
                                                    onClick={() =>
                                                        this.handleMealReplace()
                                                    }
                                                    data-test="change-meal-button"
                                                    className="px-0 w-100"
                                                    style={{
                                                        minWidth: '10px',
                                                    }}
                                                >
                                                    {this.state
                                                        .preloadInside && (
                                                        <span
                                                            className="spinner-border spinner-border-sm mr-2"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                    )}
                                                    {this.props.t(
                                                        'meal/exchange-meal-button'
                                                    )}
                                                </Button>
                                            </Col>
                                        )}

                                    <Col className="pl-2 text-center">
                                        <Button
                                            color="secondary"
                                            size="sm"
                                            outline={isMealEaten}
                                            data-test="change-meal-button"
                                            className={
                                                isMealEaten ||
                                                this.props.name === 'Start'
                                                    ? ''
                                                    : 'w-100'
                                            }
                                            onClick={() =>
                                                this.handleSetMealEaten()
                                            }
                                            style={
                                                isMealEaten
                                                    ? {
                                                          minWidth: '140px',
                                                      }
                                                    : {
                                                          minWidth: '100px',
                                                      }
                                            }
                                        >
                                            {isMealEaten && <IconDone />}

                                            {isMealEaten && (
                                                <>&nbsp;&nbsp;&nbsp;</>
                                            )}
                                            {this.props.t('meal/eaten-button')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </section>
        );
    }
}

export { DayPlanMealDetails };
export default withFirebase(
    withSnackbar(withMobileAppMode(withLocale(DayPlanMealDetails)))
);
